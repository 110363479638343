import { useState } from "react";

import DstButton from "../../../../../components/DstButton/DstButton";
import MultiSelectLiveDropdown from "../../../../../components/MultiSelectLiveDropdown/MultiSelectLiveDropdown";
import Popup from "../../../../../components/Popup/Popup";
import translate from "../../../../../services/Translate";
import { updateUsers } from "../CohortUsers.function";
import { CohortUsersPopupProperties } from "./AddUsersToOtherCohortPopup.d";

const AddUsersToOtherCohortPopup = ({
  language,
  openAddUsersToOtherCohortModal,
  setOpenAddUsersToOtherCohortModal,
  setUsersSelected,
  usersToAddToCohort,
}: CohortUsersPopupProperties) => {
  const [targetCohortId, setTargetCohortId] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  return (
    <Popup
      show={openAddUsersToOtherCohortModal}
      onClose={() => setOpenAddUsersToOtherCohortModal(false)}
      modalTitle={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_USERS_TO_OTHER_COHORT.TITLE")}
    >
      <div className="d-flex flex-column">
        <div className="flex-grow-1">
          <MultiSelectLiveDropdown
            category={["cohorts"]}
            label={
              targetCohortId
                ? `${translate(
                    language,
                    "PAGES.COHORT.USERS.MODALS.ADD_USERS_TO_OTHER_COHORT.COHORT_SELECTED"
                  )} ${targetCohortId}`
                : translate(language, "PAGES.COHORT.USERS.MODALS.ADD_USERS_TO_OTHER_COHORT.SELECT_COHORT")
            }
            language={language}
            mockClass="rounded-0 rounded-end"
            toggleClass="min-w-100px m-regular"
            onConfirm={(selectedCohort: any) => setTargetCohortId(selectedCohort[0]?.value.id || "")}
            showFilterButton={false}
            clearItemsOnSend={false}
            isSingleSelect
          />
        </div>
        <div className="d-flex justify-content-end mt-4">
          <DstButton
            btnWidth="100px"
            variant="primary"
            value={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_USERS_TO_OTHER_COHORT.CONFIRM_BUTTON")}
            clickFunction={() => {
              setIsAdding(true);
              updateUsers(
                usersToAddToCohort,
                true,
                targetCohortId,
                language,
                () => {
                  alert(translate(language, "PAGES.COHORT.USERS.MODALS.ADD_USERS_TO_OTHER_COHORT.ALERTS.SUCCESS"));
                  setOpenAddUsersToOtherCohortModal(false);
                  setUsersSelected([]);
                },
                () => setIsAdding(false)
              );
            }}
            btnClass="me-2"
            disabled={isAdding}
            loading={isAdding}
          />
        </div>
      </div>
    </Popup>
  );
};

export default AddUsersToOtherCohortPopup;
