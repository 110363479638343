import { matchPath } from "react-router";

import Doc from "../components/DstIcons/Doc";
import Schema from "../components/DstIcons/Schema";
import Users from "../components/DstIcons/Users";
import { getLegacyAdminHostname } from "./UtilityService";

const PATHS = [
  {
    paths: ["users", "/user/:user_id", "cohorts", "cohort/:cohort_id"],
    items: [
      { label: "Users", to: "/users", Icon: Users, iconProps: { classes: "" } },
      { label: "Cohorts", to: "/cohorts", Icon: Schema, iconProps: { classes: "" } },
    ],
  },
  {
    paths: ["insights", "/insights/surveys"],
    items: [
      { label: "Users", to: "/insights", Icon: Users, iconProps: { classes: "" } },
      {
        label: "Surveys",
        to: "/insights/surveys",
        Icon: Doc,
        iconProps: { classes: "", bgColor: "var(--color-silver-gray)" },
      },
    ],
  },
  {
    paths: ["/evaluation-attempt/:eval_id", "/evaluations/attempts/*"],
    items: [
      {
        label: "Evaluation Attempts",
        to: "/evaluations/attempts",
        iconClass: "dst-icon-file-question-03",
      },
      {
        label: "Exercices list",
        to: `${getLegacyAdminHostname()}/eval/exercises`,
        iconClass: "dst-icon-list",
      },
      {
        label: "Recruitements",
        to: `${getLegacyAdminHostname()}/eval/recruitments`,
        iconClass: "dst-icon-graduation-hat-01",
      },
      {
        label: "Corrections",
        to: "/evaluations/attempts?status=CORRECTION_PENDING",
        iconClass: "dst-icon-file-check-02",
      },
      {
        label: "Invite",
        to: `${getLegacyAdminHostname()}/eval/invite`,
        iconClass: "dst-icon-send-03",
      },
    ],
  },
];

const provideLeftNavbarItems = (path: string) => {
  const matching_group = PATHS.map((group) => {
    if (group.paths.some((key) => matchPath(key, path))) return group;
    return null;
  }).filter((e) => e)?.[0];

  if (matching_group) {
    return matching_group.items;
  }
  return [];
};

export default provideLeftNavbarItems;
