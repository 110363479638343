import React from "react";
import { Placeholder } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useSession } from "../../contexts/SessionContext";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate, getLegacyAdminHostname } from "../../services/UtilityService";
import ExternalLink from "../DstIcons/ExternalLink";
import { ProjectBlockProps } from "./ProjectBlock.d";

const ProjectBlock = ({
  description,
  endDate,
  facilitator,
  externalDocumentsLinks,
  gradeScore,
  githubLink,
  id,
  mock = false,
  startDate,
  title,
  type,
}: ProjectBlockProps): JSX.Element => {
  const { language } = useSession();
  const navigate = useNavigate();

  if (mock)
    return (
      <Placeholder animation="glow">
        <div className="d-flex">
          <div className="col-xl-4 col-md-6 col-sm-12">
            <div className="mb-4 shadow-sm p-3 d-flex flex-column justify-content-between w-100">
              <div>
                <Placeholder as="div" xs={5} />
                <Placeholder as="h3" xs={12} />
              </div>
              <Placeholder as="div" xs={12} />
              <Placeholder as="p" xs={12} />
              <Placeholder as="p" xs={5} />
              <Placeholder as="p" xs={5} />
            </div>
          </div>
        </div>
      </Placeholder>
    );

  return (
    <div
      className="mb-4 shadow-sm p-3 beige-background d-flex flex-column justify-content-between w-100 clickable"
      onClick={(event: React.MouseEvent) =>
        completeNavigate(event, navigate, `${getLegacyAdminHostname()}/group_projects/${id}`)
      }
    >
      <div>
        <div className="smoke-gray-font s-regular">{type}</div>
        <h3 className="charcoal-gray-font">{title}</h3>
      </div>
      <p className="dark-gray-font xs-regular">{description}</p>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="yellow-font s-regular mb-0">
            {translate(language, "COMPONENTS.PROJECT_BLOCK.FACILITATED").replace("{{overseer}}", facilitator)}
          </p>
          <p className="charcoal-gray-font s-regular">
            {formatDateHumanly(language, startDate)} - {formatDateHumanly(language, endDate)}
          </p>
        </div>
        {githubLink && (
          <a href={githubLink} target="_blank" rel="noopener noreferrer">
            <ExternalLink />
          </a>
        )}
      </div>
      <div>
        {gradeScore && (
          <p className="charcoal-gray-font s-bold mb-0">
            {translate(language, "COMPONENTS.PROJECT_BLOCK.GRADE")} {gradeScore}
          </p>
        )}
        {externalDocumentsLinks && (
          <div>
            <p className="charcoal-gray-font s-regular mb-0">
              {translate(language, "COMPONENTS.PROJECT_BLOCK.EXTERNAL_DOCUMENT_URLS")}
            </p>
            {externalDocumentsLinks?.map((externalDocumentsLink: string, index: number) => (
              <a
                href={externalDocumentsLink}
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
                key={`external-document-url-${index}`}
                className="m-2 ms-0 btn btn-secondary s-regular"
              >
                {translate(language, "COMPONENTS.PROJECT_BLOCK.EXTERNAL_DOCUMENT")} {index}{" "}
                <i className="dst-icon-link-external-02 ms-1"></i>
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectBlock;
