import ProjectBlock from "../../../../components/ProjectBlock/ProjectBlock";
import translate from "../../../../services/Translate";

const UserProjects = ({
  user_data,
  language,
  loaded,
}: {
  user_data: any;
  language: string;
  loaded: boolean;
}): JSX.Element => {
  return (
    <div className="w-100">
      <h2 className="mb-3">{translate(language, "PAGES.USER.PROJECT.TITLE")}</h2>
      <div className="row w-100">
        {loaded ? (
          user_data.user_projects.length > 0 ? (
            user_data.user_projects.map((project: any, index: number) => (
              <div key={index} className="col-xl-4 col-md-6 col-sm-12 d-flex">
                <ProjectBlock
                  id={project.id}
                  type={project.type_name}
                  title={project.name}
                  description={project.description}
                  facilitator={project.overseer}
                  startDate={project.start_dt}
                  endDate={project.end_dt}
                  githubLink={project.github_link}
                  gradeScore={project.score ? `${project.score} / ${project.score_base}` : undefined}
                  externalDocumentsLinks={project.external_document_urls}
                />
              </div>
            ))
          ) : (
            <p>{translate(language, "PAGES.USER.PROJECT.NO_PROJECT")}</p>
          )
        ) : (
          <ProjectBlock
            mock
            id=""
            type=""
            title=""
            description=""
            facilitator=""
            startDate=""
            endDate=""
            githubLink=""
          />
        )}
      </div>
    </div>
  );
};

export default UserProjects;
