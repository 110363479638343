import { autocomplete } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";
import { meilisearchAutocompleteClient } from "@meilisearch/autocomplete-client";
import React, { useEffect, useRef, useState } from "react";
import { Badge, Col, Dropdown, InputGroup, Placeholder, Row } from "react-bootstrap";

import translate from "../../services/Translate";
import DstButton from "../DstButton/DstButton";
import {
  MultiSelectLiveDropdownProps,
  MultiSelectLiveDropdownPropsSelectItems,
  categoryAvailable,
} from "./MultiSelectLiveDropdown.d";
import { categoryProperty, getMeilisearchSources } from "./MultiSelectLiveDropdown.function";
import "./MultiSelectLiveDropdown.scss";

const MultiSelectLiveDropdown = ({
  btnWidth = "120px",
  category,
  clearOnConfirm,
  clearItemsOnSend = false,
  confirmOnClose = false,
  disabled = false,
  hideCaret = false,
  hideOnConfirm = true,
  initialSelectedItems = [],
  isSingleSelect = false,
  label,
  language,
  mock = false,
  mockClass = "",
  onConfirm,
  showFilterButton = false,
  toggleClass = "min-w-100px",
}: MultiSelectLiveDropdownProps) => {
  const [selectedItems, setSelectedItems] = useState<MultiSelectLiveDropdownPropsSelectItems[]>(initialSelectedItems);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterCategory, setFilterCategory] = useState<categoryAvailable | null>(null);
  const container = useRef<HTMLDivElement>(null);
  const initialItemsSet = useRef(false);

  const _handleRemoveItem = (itemToRemove: MultiSelectLiveDropdownPropsSelectItems) => {
    setSelectedItems((currentItems) =>
      isSingleSelect
        ? []
        : currentItems.filter(
            (item) => item.category !== itemToRemove.category || item.value.id !== itemToRemove.value.id
          )
    );
  };

  const handleConfirmClick = () => {
    onConfirm(selectedItems);
    if (clearOnConfirm) {
      setSelectedItems([]);
    }
    setDropdownOpen(!hideOnConfirm);
  };

  useEffect(() => {
    if (!container.current) {
      return;
    }

    if (!initialItemsSet.current && initialSelectedItems.length) {
      onConfirm(initialSelectedItems);
      initialItemsSet.current = true;
    }

    const searchClient = meilisearchAutocompleteClient({
      url: process.env.REACT_APP_MEILISEARCH_URL!,
      apiKey: process.env.REACT_APP_MEILISEARCH_API_KEY,
    });

    const search = autocomplete({
      container: container.current!,
      placeholder: translate(language, "COMPONENTS.MULTI_SELECT.SEARCH_PLACEHOLDER"),
      defaultActiveItemId: 0,
      classNames: showFilterButton
        ? {
            root: "h-100",
            form: "h-100 rounded-0 rounded-start",
          }
        : {},
      getSources({ query }: { query: string }) {
        if (!query) {
          return [];
        }
        return getMeilisearchSources(
          searchClient,
          category || "users",
          query,
          (item: any, category: string) => {
            setSelectedItems((value) => {
              if (isSingleSelect) {
                return [{ value: item, category }];
              } else if (!value.some((selected) => selected.value.id === item.id)) {
                return [...value, { value: item, category }];
              }
              return value;
            });
          },
          selectedItems,
          filterCategory
        );
      },
    });

    if (clearItemsOnSend) {
      setSelectedItems([]);
    }
    container.current.querySelector("input")?.focus();

    return () => {
      search.destroy();
    };
  }, [
    language,
    category,
    selectedItems,
    filterCategory,
    showFilterButton,
    clearItemsOnSend,
    initialSelectedItems,
    isSingleSelect,
    onConfirm,
  ]);

  if (mock) {
    return (
      <Placeholder animation="glow">
        <Placeholder.Button
          style={{
            height: "100%",
            width: btnWidth,
          }}
          className={mockClass}
          aria-hidden="true"
        />
      </Placeholder>
    );
  }

  return (
    <Dropdown
      autoClose="inside"
      className="multi-select-live-dropdown"
      show={dropdownOpen}
      onToggle={(isOpen) => {
        if (disabled) {
          return;
        }
        setDropdownOpen(isOpen);
      }}
    >
      <Dropdown.Toggle
        variant="input"
        className={`text-left align-items-center justify-content-between d-flex ${toggleClass} ${
          hideCaret ? "caret-hide" : ""
        }`}
      >
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-3 min-w-450px s-regular">
        <InputGroup className="mb-3">
          <div ref={container}></div>
          {showFilterButton &&
            [category]
              .flat()
              .map((category: categoryAvailable, index) => (
                <DstButton
                  key={`filter-button-${index}`}
                  value={`Only ${category}`}
                  variant={filterCategory === category ? "primary" : "secondary"}
                  btnClass="ms-0 xs-regular"
                  clickFunction={() =>
                    setFilterCategory((categoryFiltering) => (categoryFiltering === category ? null : category))
                  }
                />
              ))}
        </InputGroup>
        <Row>
          <Col md={9}>
            <div className="selected-items mt-2">
              {selectedItems.map((item, index) => (
                <Badge
                  key={`chosen-${index}`}
                  pill
                  className="selected-item-badge me-1 d-inline-flex w-fit align-items-center"
                  bg="dropdown"
                >
                  <span>
                    {(categoryProperty as any)[item.category]
                      ?.map((property: string) => item.value[property])
                      .join(" ")}
                  </span>
                  <i className="dst-icon-x clickable" onClick={() => _handleRemoveItem(item)}></i>
                </Badge>
              ))}
              <div className="xs-regular">
                {selectedItems.length}{" "}
                {selectedItems.length > 1
                  ? translate(language, "COMPONENTS.MULTI_SELECT.ITEMS")
                  : translate(language, "COMPONENTS.MULTI_SELECT.ITEM")}{" "}
                {translate(language, "COMPONENTS.MULTI_SELECT.SELECTED")}
              </div>
            </div>
          </Col>
          <Col md={3} className="text-end align-self-end">
            {!confirmOnClose && (
              <DstButton
                btnClass="mt-2"
                clickFunction={handleConfirmClick}
                value={translate(language, "PAGES.USER.OVERVIEW.MODULE.CONFIRM")}
                btnSize="lg"
              />
            )}
          </Col>
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectLiveDropdown;
