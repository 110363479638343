import ApiService from "../../../../../services/ApiService";
import translate from "../../../../../services/Translate";

export const fetchEvaluations = (setEvaluations: Function) => {
  ApiService.get("evaluations")
    .then((response) => setEvaluations(response.data.evaluations))
    .catch((error) => console.error("Failed to fetch evaluations or certifications", error));
};

export const inviteSelectedUsersToEvaluation = (
  cohortId: number,
  selectedEvaluationsId: number,
  userIds: Array<number>,
  language: string,
  scheduledDate: string | undefined,
  forceInvite: boolean,
  finalCallBackFunction: Function,
  successCallBackFunction: Function
) => {
  ApiService.post(`/cohorts/${cohortId}/users/evaluations/${selectedEvaluationsId}/attempts`, {
    user_ids: userIds,
    send_email: true,
    ...(scheduledDate && { dt_start: scheduledDate }),
    ...(forceInvite && { on_failed: forceInvite }),
  })
    .then((response) => {
      alert(
        translate(language, "PAGES.COHORT.USERS.MODALS.EVALUATION_INVITE.ALERTS.SUCCESS").replace(
          "{{COUNT}}",
          response.data.message.split(" ")[0]
        )
      );
      successCallBackFunction();
    })
    .catch((error) => {
      if (error.data?.error?.message.includes("must be a future date and time")) {
        console.error("A past date error occurred:", error);
        alert(translate(language, "PAGES.COHORT.USERS.MODALS.EVALUATION_INVITE.ALERTS.PAST_DATE_ERROR"));
      } else {
        console.error("An API error occurred:", error);
        alert(translate(language, "ERROR.API_ERROR"));
      }
    })
    .finally(() => finalCallBackFunction());
};
