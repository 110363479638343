import ApiService from "../../../../../services/ApiService";
import translate from "../../../../../services/Translate";

export const generatePartnerDiploma = (
  language: string,
  cohortId: string,
  courseName: string,
  userIds: Array<number>,
  closeModal: Function,
  setSending: Function
) => {
  setSending(true);
  ApiService.post(`cohorts/${cohortId}/partners/diplomas`, { course_name: courseName, user_ids: userIds })
    .then((_) => {
      alert(translate(language, "PAGES.COHORT.USERS.MODALS.PARTNER_DIPLOMAS.ALERTS.SUCCESS"));
      closeModal();
    })
    .catch((err) => {
      console.error("An error occurred while generating partner diplomas for users", err);
      alert(translate(language, "ERROR.API_ERROR"));
    })
    .finally(() => setSending(false));
};
