import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Form, Table } from "react-bootstrap";

import { getNestedProperty } from "../../services/UtilityService";
import DstButton from "../DstButton/DstButton";
import { DstTableColumns, DstTableProps } from "./DstTable.d";
import "./DstTable.scss";

const _prepareHeader = (input: Array<any>) => {
  let result: any[] = [];

  input.forEach((element: any) => {
    if (element.key) {
      result[0] = [...(result[0] ?? []), element];
    } else if (element.children) {
      result[0] = [
        ...(result[0] ?? []),
        {
          name: element.name,
          size: element.children.length,
        },
      ];
      result[1] = [...(result[1] ?? []), element.children].flat();
    }
  });
  return result;
};

export const DstTable = ({
  classLine,
  columns,
  data,
  itemsPerPage = 10,
  loading = false,
  onRowClick,
  onRowSelect,
  pagination = false,
  paginationAlwaysShowFooter = false,
  selectable = false,
  selectedItems = [],
  striped = true,
  stylishLine,
}: DstTableProps) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: "ascending" | "descending";
    sortCompute?: Function;
  }>({
    key: null,
    direction: "ascending",
  });

  const [selectedRows, setSelectedRows] = useState<any[]>(selectedItems);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (
      selectable &&
      selectedRows
        .map((elem) => elem.id)
        .sort()
        .toString() !==
        selectedItems
          .map((elem) => elem.id)
          .sort()
          .toString()
    ) {
      setSelectedRows(selectedItems);
    }
  }, [selectable, selectedRows, selectedItems]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, item: any) => {
    event.stopPropagation();
    const newSelectedRows = selectedRows.map((row) => row.id).includes(item.id)
      ? selectedRows.filter((row) => row.id !== item.id)
      : [...selectedRows, item];

    setSelectedRows(newSelectedRows);
    onRowSelect?.(newSelectedRows);
  };

  const handleSelectAllChange = () => {
    const elements = selectedRows.length === data.length ? [] : data;
    setSelectedRows(elements);
    onRowSelect?.(elements);
  };

  const requestSort = (column: DstTableColumns) => {
    setSortConfig((currentSortConfig) => ({
      key: column.key!,
      sortCompute: column?.sortCompute,
      direction:
        currentSortConfig.key === column.key && currentSortConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const sortItems = (items: any[], key: string, direction: string) => {
    return items.sort((a, b) => {
      const aValue = getNestedProperty(a, key) || "";
      const bValue = getNestedProperty(b, key) || "";
      if (typeof aValue === "number" && typeof bValue === "number") {
        return direction === "ascending" ? aValue - bValue : bValue - aValue;
      }
      return direction === "ascending"
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });
  };

  const tableHeaders = useMemo(() => {
    return _prepareHeader(columns);
  }, [columns]);

  const sortedItems = useMemo(() => {
    let tmpData = [...data];
    if (!!sortConfig.sortCompute && !!sortConfig.key) {
      tmpData = tmpData.map((elem) => ({
        ...elem,
        [sortConfig.key!]: sortConfig.sortCompute!(elem),
      }));
    }
    return sortConfig.key ? sortItems(tmpData, sortConfig.key, sortConfig.direction) : tmpData;
  }, [data, sortConfig]);

  const totalPages = useMemo(() => Math.ceil(sortedItems.length / itemsPerPage), [sortedItems, itemsPerPage]);

  const paginatedItems = useMemo(() => {
    if (!pagination) return sortedItems;
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedItems.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedItems, currentPage, itemsPerPage, pagination]);

  const pageButtons = useMemo(() => {
    const maxButtons = 5;
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, totalPages);

    if (endPage === totalPages) {
      startPage = Math.max(totalPages - maxButtons + 1, 1);
    }

    return Array.from({ length: Math.min(maxButtons, totalPages) }, (_, i) => startPage + i);
  }, [currentPage, totalPages]);

  return (
    <React.Fragment>
      <Table striped={striped} bordered responsive className="s-regular dst-table" hover={!!onRowClick}>
        <thead>
          <tr>
            {selectable && (
              <th className="checkbox-cell text-center align-content-center">
                <Form.Check
                  type="checkbox"
                  checked={selectedRows.length === data.length && data.length > 0}
                  onChange={handleSelectAllChange}
                />
              </th>
            )}
            {tableHeaders.map((row: any, rowIndex: number) => (
              <React.Fragment key={`header-row-${rowIndex}`}>
                {row.map((column: any, colIndex: number) => (
                  <th
                    key={`header-col-${rowIndex}-${colIndex}`}
                    rowSpan={column.size ? 1 : 2}
                    colSpan={column?.size ?? 1}
                    onClick={() => requestSort(column)}
                    className="clickable align-content-center border-table"
                    style={column.stylishTitle ? column.stylishTitle() : {}}
                  >
                    {column.name}
                    {sortConfig.key === column.key && (
                      <span
                        className={`ms-2 ${
                          sortConfig.direction === "descending"
                            ? "dst-icon-chevron-down-double"
                            : "dst-icon-chevron-up-double"
                        }`}
                      />
                    )}
                  </th>
                ))}
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading
            ? Array.from({ length: 5 }).map((_item, rowIndex) => (
                <tr key={`mock-row-${rowIndex}`}>
                  {selectable && (
                    <td className="text-center align-content-center">
                      <Form.Check disabled />
                    </td>
                  )}
                  {tableHeaders
                    .flat()
                    .filter((element: any) => !!element.key)
                    .map((element: any, colIndex: number) => (
                      <td key={`mock-cell-${rowIndex}-${colIndex}`}>
                        <div className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </div>
                      </td>
                    ))}
                </tr>
              ))
            : paginatedItems.map((item, rowIndex) => (
                <tr
                  key={item.id || `row-${rowIndex}`}
                  onClick={(event) => onRowClick?.(event, item)}
                  className={`${onRowClick ? "clickable" : ""} ${classLine?.(item) || ""}`}
                >
                  {selectable && (
                    <td className="text-center align-content-center" onClick={(e) => e.stopPropagation()}>
                      <Form.Check
                        type="checkbox"
                        checked={selectedRows.map((row) => row.id).includes(item.id)}
                        onChange={(event) => handleCheckboxChange(event, item)}
                      />
                    </td>
                  )}
                  {tableHeaders
                    .flat()
                    .filter((element: any) => !!element.key)
                    .map((element: any, cellIndex: number) => (
                      <td
                        key={`data-${rowIndex}-${cellIndex}`}
                        style={{ ...stylishLine?.(item), ...element?.stylishCell?.(item) }}
                      >
                        {element.render ? element.render(item) : getNestedProperty(item, element.key)}
                      </td>
                    ))}
                </tr>
              ))}
        </tbody>
      </Table>
      {!loading && (pagination || paginationAlwaysShowFooter) && totalPages > 1 && (
        <div className="pagination-controls d-flex justify-content-center align-items-center m-3">
          <DstButton
            btnClass="button-lister dst-icon-chevron-left-double me-1"
            clickFunction={() => setCurrentPage(1)}
            value=""
            disabled={currentPage === 1}
          />
          <DstButton
            btnClass="button-lister dst-icon-chevron-left me-1"
            clickFunction={() => setCurrentPage(Math.max(currentPage - 5, 1))}
            value=""
            disabled={currentPage < 4}
          />
          {pageButtons.map((page) => (
            <DstButton
              btnClass="button-lister ms-1"
              key={page}
              value={page.toString()}
              variant={currentPage === page ? "primary" : "secondary"}
              clickFunction={() => setCurrentPage(page)}
            />
          ))}
          <DstButton
            btnClass="button-lister dst-icon-chevron-right ms-2"
            value=""
            clickFunction={() => setCurrentPage(Math.min(currentPage + 5, totalPages))}
            disabled={currentPage > totalPages - 3}
          />
          <DstButton
            btnClass="button-lister dst-icon-chevron-right-double ms-1"
            clickFunction={() => setCurrentPage(totalPages)}
            value=""
            disabled={currentPage === totalPages}
          />
          <span className="ms-3">
            {paginatedItems.length + (currentPage - 1) * itemsPerPage} / {sortedItems.length}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export default DstTable;
