import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

export const getExamStatusStyle = (examStatus: string) => {
  switch (examStatus) {
    case "success":
      return "success";
    case "failed":
      return "danger";
    case "repass":
      return "warning";
    default:
      return "light";
  }
};

export const downloadEvaluationFile = (evaluationRender: any) => {
  const link = document.createElement("a");
  link.href = evaluationRender.submission_infos.presigned_url;
  link.setAttribute("download", evaluationRender.submission_infos.filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const checkAllCorrected = (evaluationRender: any, setIsAllCorrected: any) => {
  setIsAllCorrected(Array.isArray(evaluationRender) && evaluationRender.every((item: any) => item.is_correct !== null));
};

export const startCorrection = (eval_id: any, handleShowOverlay: Function, languages: string) => {
  ApiService.post(`evaluations/attempts/${eval_id}/correction/start`, {})
    .then((_) => {
      handleShowOverlay();
    })
    .catch((err) => {
      console.error(err);
      alert(translate(languages, "PAGES.EVAL.ERROR.START_CORRECTION"));
    });
};

export const sendCorrection = (
  language: string,
  evaluationAttemptId: any,
  editorContent: string,
  examStatus: string,
  score?: number,
  score_base: number = 20,
  answers_correction = {},
  callback?: Function,
  finalCallback?: Function
) => {
  ApiService.post(`evaluations/attempts/${evaluationAttemptId}/correct`, {
    status: examStatus,
    comment: editorContent,
    ...(score !== undefined && score !== null ? { score, score_base } : {}),
    ...(!!Object.keys(answers_correction).length ? { answers_correction } : {}),
  })
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error("Error in post evaluations:", error);
      alert(translate(language, "PAGES.EVAL.ERROR.SEND_CORRECTION"));
    })
    .finally(() => finalCallback?.());
};
