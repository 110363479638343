import { useState } from "react";

import CertifBlock from "../../../../components/CertifBlock/CertifBlock";
import DstButton from "../../../../components/DstButton/DstButton";
import { formatMinutesHourly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";

const UserCertification = ({
  user_data,
  loaded,
  language,
  refreshUserCertifications,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
  refreshUserCertifications: Function;
}): JSX.Element => {
  const [filterType, setFilterType] = useState<"all" | "mandatory" | "optional">("all");

  const filteredCertifications =
    user_data.user_certifs?.filter((certif: any) => {
      if (["optional", "mandatory"].includes(filterType)) return certif.is_mandatory === (filterType === "mandatory");
      return true;
    }) || [];

  return (
    <div className="w-100">
      <h2>{translate(language, "PAGES.USER.CERTIFICATION.TITLE")}</h2>
      {loaded ? (
        <div className="mt-3">
          <DstButton
            variant="info"
            btnClass={`me-2 ${filterType === "all" ? "active-filter-btn" : ""}`}
            clickFunction={() => setFilterType("all")}
            value={`${translate(language, "PAGES.USER.CERTIFICATION.FILTER.ALL")} (${
              user_data.user_certifs?.length || 0
            })`}
          />
          <DstButton
            variant="info"
            btnClass={`me-2 ${filterType === "mandatory" ? "active-filter-btn" : ""}`}
            clickFunction={() => setFilterType("mandatory")}
            value={`${translate(language, "PAGES.USER.CERTIFICATION.FILTER.MANDATORY")} (${
              user_data.user_certifs?.filter((certif: any) => certif.is_mandatory === true).length || 0
            })`}
          />
          <DstButton
            variant="info"
            btnClass={filterType === "optional" ? "active-filter-btn" : ""}
            clickFunction={() => setFilterType("optional")}
            value={`${translate(language, "PAGES.USER.CERTIFICATION.FILTER.OPTIONAL")} (${
              user_data.user_certifs?.filter(
                (certif: any) => certif.is_mandatory === false || certif.is_mandatory === null
              ).length || 0
            })`}
          />
        </div>
      ) : (
        <div className="d-flex">
          <DstButton mock btnWidth="64px" btnClass="me-2" />
          <DstButton mock btnWidth="110px" btnClass="me-2" />
          <DstButton mock btnWidth="90px" />
        </div>
      )}
      <div className="scrollable py-3 mb-3" id="certif-scrollable">
        <div className="d-inline-flex flex-nowrap">
          {loaded ? (
            filteredCertifications.length > 0 ? (
              filteredCertifications.map((certif: any, index: number) => (
                <CertifBlock
                  key={index}
                  attempts={certif.attempt_count}
                  certifDate={certif.dt_created}
                  documentId={certif.document_id}
                  filename={certif.filename}
                  mainText={certif.certification_name}
                  remarkText={certif.duration ? formatMinutesHourly(certif.duration) : ""}
                  topLeftTitle={certif.sprint_name}
                  userFullName={`${user_data.first_name} ${user_data.last_name}`}
                  refreshUserCertifications={refreshUserCertifications}
                />
              ))
            ) : (
              <p>{translate(language, "PAGES.USER.CERTIFICATION.NO_CERTIFICATION")}</p>
            )
          ) : (
            [...Array(5)].map((_, index: number) => <CertifBlock mock={true} key={`mock-certif-block-${index}`} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCertification;
